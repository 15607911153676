import React from 'react';
import Testimonial from '@src/modules/home/Testimonial';

const TestimonialRow = () => {
  return (
    <div
      className="feedback-section-three position-relative mt-120 lg-mt-100 pb-100"
      style={{ zIndex: 1 }}
      data-data-aos="fade-up"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 ms-auto">
            <div className="title-style-four text-center text-lg-start lg-pb-30">
              <div className="sc-title" style={{ color: '#6A45FF' }}>
                Testimonials
              </div>
              <h2 className="main-title fw-500 tx-dark m0">
                You&apos;re gonna love us{' '}
                <span className="green">forever.</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="slider-wrapper">
          <div className="feedback_slider_three">
            <Testimonial />
          </div>
        </div>
      </div>

      <div className="shape-holder">
        <img src="/images/large_icon_1.png" alt="shape" className="lazy-img" />
      </div>
    </div>
  );
};

export default TestimonialRow;
