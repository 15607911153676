import React from 'react';
import Slider from 'react-slick';

const items = [
  {
    name: 'Tudor Mirza',
    location: 'Talent Acquisition Partner & Senior IT Recruiter @Flutter',
    image: '/images/testimonial/tm.png',
    rating: 5,
    feedback:
      'InterviewsUp is a comprehensive and user-friendly platform. It saves me time by identifying necessary soft skills and relevant questions. And the help of AI is a nice touch.',
  },
  {
    name: 'Cosmina Nicolau',
    location: 'Head of People & Culture @Druid',
    image: '/images/testimonial/cn.png',
    rating: 5,
    feedback:
      'Great job, guys! This much-needed, user-friendly application provides interview questions for different seniority levels. Now I can have interview guides ready in no time.',
  },
  {
    name: 'Camelia Deaconu',
    location: 'People & Culture Manager @Code11',
    image: '/images/testimonial/cd.png',
    rating: 5,
    feedback:
      'This tool eliminates time-consuming interview preparation, providing me effective questions and empowering me to have engaging, insightful conversations with all candidates.',
  },
  {
    name: 'Raisa Oprea',
    location: 'Senior IT Recruiter Expert @OMV Petrom',
    image: '/images/testimonial/ro.png',
    rating: 5,
    feedback:
      "InterviewsUp is invaluable for interviewers, generating top interview questions and allowing busy recruiters like me to focus on what truly matters - the candidates' soft skills.",
  },
];

const FeedbackSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    // slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      //   {
      //     breakpoint: 1200,
      //     settings: {
      //       slidesToShow: 2,
      //     },
      //   },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {items.map((item, index) => (
        <div key={index} className="item">
          <div className="feedback-block-three">
            <ul className="style-none d-flex rating">
              {[...Array(item.rating)].map((_, index) => (
                <li key={index}>
                  <i className="bi bi-star-fill" />
                </li>
              ))}
            </ul>
            <p>{item.feedback}</p>
            <div className="d-flex align-items-center">
              <img
                src={item.image}
                alt="media"
                className="avatar rounded-circle"
              />
              <h6 className="name fs-20 fw-500 m0 ps-4">
                {item.name}{' '}
                <span className="d-block fw-normal">{item.location}</span>
              </h6>
              {/*<img
                src="/images/icon/icon_35.svg"
                alt="media"
                className="ms-auto"
              />*/}
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default FeedbackSlider;
