import HeroBtnGroup from './HeroBtnGroup';

const Hero = () => {
  return (
    <>
      <h1 className="hero-heading fw-bold mt-45 mb-40 md-mt-20 text-white text-center">
        The place for all the best <br /> interview questions. <br />
        Save time and hire smarter.
      </h1>
      <p className="text-xlg mb-50 lg-mb-30 pe-xxl-4 text-white text-center fw-semibold">
        Upgrade your interviewing skills and say goodbye to interview chaos.
        <br />
        Address the best questions to spot the right candidate.
      </p>
      <HeroBtnGroup />
    </>
  );
};

export default Hero;
