const Features2 = () => {
  const listItems = [
    'AI can help you select the best competencies for your role.',
    'Create different interviewing guides for each internal stakeholder (e.g., HR, hiring managers, individual contributors).',
    'Swift collaboration between recruiters and hiring managers. You can easily create and share interview guides with everyone involved.',
  ];

  return (
    <div className="block-style-three" data-aos="fade-right">
      {/*<div className="title-style-four">*/}
      {/*  */}
      {/*</div>*/}
      {/* /.title-style-four */}
      {/*<p className="text-lg pt-35 pb-30 lg-pb-10">*/}
      {/*  Things go wrong have questions. We’ve understand. So we have people*/}
      {/*</p>*/}
      <ul className="style-none list-item fs-18">
        {listItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default Features2;
